html,
body {
  width: 100%;
  height: 100%;
}

:global #app {
  width: 100%;
  height: 100%;
}

:root {
  --application-header-height: 64px;
}
