.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--application-header-height);
  padding: 0 16px;
  background-color: white;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
}

.logo {
  display: flex;
  align-items: center;
  padding: 8px;
  text-decoration: none;
}

.logoIcon {
  display: block;
}

.logoText {
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
  padding-left: 24px;
  color: #222;
  font-size: 16px;
}
.logoText::before {
  position: absolute;
  top: 0;
  left: 12px;
  display: block;
  content: '';
  width: 1px;
  height: 24px;
  background-color: #ddd;
  transform: rotate(15deg);
}

.button {
  padding: 8px;
  color: #222;
  font-size: 14px;
}
.button:hover {
  background-color: #fff;
  border-radius: 4px;
}
