.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: var(--application-header-height);
}

.markdownEditor {
  flex-shrink: 1;
  flex-basis: 1;
  flex-basis: auto;
  height: calc(100% - var(--application-header-height));
}
