:global :root {
  --one-dark-dark-black: #1c1e23;
  --one-dark-light-black: #282c34;
  --one-dark-white: #abb2bf;
  --one-dark-light-red: #e06c75;
  --one-dark-dark-red: #be5046;
  --one-dark-green: #98c379;
  --one-dark-light-yellow: #e5c07b;
  --one-dark-dark-yellow: #d19a66;
  --one-dark-blue: #61afef;
  --one-dark-magenta: #c678dd;
  --one-dark-cyan: #56b6c2;
  --one-dark-gutter-grey: #4b5263;
  --one-dark-comment-grey: #5c6370;
}

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.editor {
  width: 50%;
  height: 100%;
}

.preview {
  width: 50%;
  height: 100%;
  overflow-y: scroll;
}
